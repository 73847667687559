import calcMock from "./calulator-mock";
class PriceList {
    constructor() {
        this.priceListTable = document.querySelector("#price-table");
        if (!this.priceListTable)
            return;
        this.priceList = this.getPriceList();
        this.priceListGroup = this.groupByCategoryId(this.priceList);
        this.renderTable();
    }
    getPriceList() {
        return calcMock.priceList;
    }
    groupByCategoryId(priceList) {
        let result = {};
        priceList.forEach((item) => {
            const id = "cat_" + item.categoryId;
            if (typeof result[id] === "undefined") {
                result[id] = [];
            }
            result[id].push(item);
        });
        return result;
    }
    renderTable() {
        for (const [categoryId, value] of Object.entries(this.priceListGroup)) {
            const thead = this.getThead(value[0]["categoryName"], value[0]["categoryId"]);
            const tbody = this.getTbody();
            const items = value;
            items.map((tr) => {
                tbody.appendChild(this.getTr(tr));
            });
            this.priceListTable.appendChild(thead);
            this.priceListTable.appendChild(tbody);
        }
    }
    getThead(title, id) {
        const thead = document.createElement("thead");
        thead.innerHTML = `<tr id="tr_${id}" class="price--table__title"><td colspan="3">${title}</td></tr>
            <tr class="price--table__head"><th scope="col">Вид лома</th><th scope="col">Цена за тонну</th><th scope="col">Цена за килограмм</th></tr>`;
        return thead;
    }
    getTbody() {
        const tbody = document.createElement("tbody");
        tbody.classList.add("price--table__body");
        return tbody;
    }
    getTr(item) {
        const tr = document.createElement("tr");
        tr.innerHTML = `<td><span class="price--table__code">${item.name}</span><span class="price--table__name">${item.desc}</span></td>
            <td>${item.pricePerTon} ₽</td><td>${item.pricePerKilogram} ₽</td>`;
        return tr;
    }
}
export default PriceList;
